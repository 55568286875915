<template>
    <div class="home-content">
        <v-container id="intro" class="cyan mb-4" fluid>
            <v-row no-gutters>
                <v-col cols="3" class="live-wird">
                    <v-img
                        :src="require('@/assets/wird-live-webagentur.svg')"
                    ></v-img>
                </v-col>
                <v-col cols="1">
                </v-col>
                <v-col cols="8" class="wird-webmacherin align-self-end">
                    <v-img
                        :src="require('@/assets/wird-webmacherin.svg')"
                    ></v-img>
                </v-col>
            </v-row>
        </v-container>
        <v-container id="intro-text">
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned" color="purple darken-1" elevation="0" tile>
                        <v-responsive  :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 3/1" class="align-center">
                            <!--<v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Sommerurlaub</h2>
                                <h3 class="mb-5" :class="$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2'">Sommerzeit ist Urlaubszeit</h3>
                                <p>Ich nutze den Sommer um durch die Vorarlberger Alpen zu wandern und mich zu erholen.</p>
                                <p>Ab September 2024 bin ich wieder für euch da.</p>
                            </v-card-text>-->
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Neukunden</h2>
                                <h3 class="mb-5" :class="$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2'">Projektstart 2025</h3>
                                <p class="text-large">Liebe Stammkunden, danke für eure Treue. Ihr beschäftigt mich den Rest des Jahres mit spannenden Aufgaben.</p>
                                <p class="text-large">Für Neukunden oder größere Projekte habe ich ab 2025 wieder Kapazitäten.</p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" lg="4" order-lg="1">
                    <v-card class="patterned" color="lime darken-1" elevation="0" tile>
                        <v-responsive  :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Neuer Name - Gleicher Service</h2>
                                <h3 :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-1'">Hallo! Ich bin die WEBMACHERIN.</h3>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="4" order-lg="3">
                    <FlipCard>
                        <template slot="front">
                            <v-card color="lime darken-1" elevation="0" tile>
                                <v-img
                                    alt="Die Webmacherin mit Katze als Illustration - Illustratorin: Mona Nagel"
                                    aspect-ratio="1"
                                    :src="require('@/assets/images/avatar.svg')"
                                ></v-img>
                            </v-card>
                        </template>
                        <template slot="back">
                            <v-card color="lime darken-1" elevation="0" tile>
                                <v-img
                                    alt="Die Webmacherin mit Katze als Foto"
                                    aspect-ratio="1"
                                    :src="require('@/assets/images/team/portrait-sara-katze.webp')"
                                ></v-img>
                            </v-card>
                        </template>
                    </FlipCard>
                </v-col>
                <v-col cols="12" lg="4" order-lg="2">
                    <v-card color="lime darken-1" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.mdAndDown ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p>Ich habe mich für einen neuen Namen entschieden, der besser zeigt wer ich bin und was ich mache.<br><strong>Nämlich Webseiten & Webshops!</strong></p>
                                <p>Für euch bleibt alles beim alten. Auch wenn ihr die alte Email-Adresse nochmal verwendet geht nichts verloren.</p>
                                <p>Die neue <strong>Email-Adresse</strong> ist übrigens</p>
                                <p class="title font-family-body font-weight-regular"><strong class="primary px-1">hallo@die-webmacherin.at</strong></p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <a id="leistungen"></a>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" lg="4">
                    <v-card color="orange" elevation="0" tile>
                        <v-img
                        alt="Eine Stadt auf der Erde in der Nacht aus Sicht der Raumstation ISS - Fotografin: NASA"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1"
                        :src="require('@/assets/images/mood/iss037e004654~large.jpg')"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                    <v-card class="patterned" color="orange" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Leistungen</h2>
                                <h3 :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">Was bietet die WEBMACHERIN?</h3>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card color="orange" elevation="0" tile>
                        <v-responsive
                        :aspect-ratio="$vuetify.breakpoint.mdAndDown ? 2/1 : 1"
                        class="align-center">
                            <v-card-text class="text-center white--text">
                                <p class="subtitle-2 text-uppercase mb-0">
                                    Kurz zusammengefasst:
                                </p>
                                <ul>
                                    <li>Konzeption & Implementierung von Webpräsenzen</li>
                                    <li>Kleine bis mittlere Business-Websites & Webshops</li>
                                    <li>Wordpress & Woocommerce</li>
                                    <li>Expertin für den Avada Website Builder für Wordpress & WooCommerce</li>
                                    <li>Erfahrung mit den meistverwendeten Wordpress Themes & Pagebuildern (Divi, Elementor, Ocean WP)</li>
                                    <li>Single Page Websites mit Vue.js & HTML & CSS</li>
                                    <li>Responsive Webdesign (für mobile Geräte optimiert)</li>
                                    <li>Performance & Accessibility</li>
                                    <li>DSGVO-Anpassungen & Datensparsamkeit</li>
                                    <li>Wartung & Anpassung bestehender Websites & Webshops</li>
                                    <li>Einschulungen in Wordpress & Woocommerce</li>
                                    <li>Workshops zu digitalen Themen (mehr Infos unter <router-link class="font-weight-bold" to="/workshops">Workshops</router-link>)</li>
                                </ul>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card color="orange" elevation="0" tile>
                        <v-responsive
                            :aspect-ratio="$vuetify.breakpoint.xs 
                                                ? 2/1 
                                                : ($vuetify.breakpoint.sm
                                                    ? 1 
                                                    : ($vuetify.breakpoint.md
                                                        ? 2/1
                                                        : ($vuetify.breakpoint.lg
                                                            ? 1 
                                                            : 1
                                                        )
                                                    )
                                                )"
                            class="align-center"
                        >
                            <v-card-text class="text-center white--text">
                                 <p>
                                    Ich biete Dir die Konzeption und Implementierung kleiner und mittelerer Business-Websites und Webshops.
                                    Dabei arbeite ich hauptsächlich mit Open Source/Free Software wie Wordpress & Woocommerce.
                                    Ich bin Expertin für den Avada Website Builder für Wordpress & WooCommerce. Und auch mit den anderen gängigen Themes & Pagebuildern (Divi, Elementor, Ocean WP) kenne ich mich aus.
                                    Für kleine Projekte oder Single Page Websites die keine CMS-Funktionen benötigen, kommt auch Vue.js oder HTML & CSS zum Einsatz.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card color="orange" elevation="0" tile>
                        <v-responsive
                            :aspect-ratio="$vuetify.breakpoint.xs 
                                                ? 2/1 
                                                : ($vuetify.breakpoint.sm
                                                    ? 1 
                                                    : ($vuetify.breakpoint.md
                                                        ? 2/1
                                                        : ($vuetify.breakpoint.lg
                                                            ? 1 
                                                            : 1
                                                        )
                                                    )
                                                )"
                            class="align-center"
                        >
                            <v-card-text class="text-center white--text">
                                 <p>
                                    Selbstverständlich werden alle Webseiten für mobile Geräte optimiert.
                                    Die Geschwindigeit meiner Webseiten wird standardmäßig optimiert und auch für Menschen mit Beeinträchtigungen sind die Webseiten gut zu bedienen.
                                    Und zu guter letzt sind die Webseiten datensparsam und DSGVO-konform.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card color="orange" elevation="0" tile>
                        <v-responsive
                            :aspect-ratio="$vuetify.breakpoint.xs 
                                                ? 2/1 
                                                : ($vuetify.breakpoint.sm
                                                    ? 1 
                                                    : ($vuetify.breakpoint.md
                                                        ? 2/1
                                                        : ($vuetify.breakpoint.lg
                                                            ? 1 
                                                            : 1
                                                        )
                                                    )
                                                )"
                            class="align-center"
                        >
                            <v-card-text class="text-center white--text">
                                <p>
                                    Außerdem unterstütze ich Dich gerne bei Weiterentwicklung und Wartung Deiner bestehenden Website oder Deinem Webshop.
                                    Das kann Hilfe bei den Einstellungen oder der Befüllung sein, Designanpassungen, Updates oder Beheben von Fehlern.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card color="orange" elevation="0" tile>
                        <v-responsive
                            :aspect-ratio="$vuetify.breakpoint.xs 
                                                ? 2/1 
                                                : ($vuetify.breakpoint.sm
                                                    ? 1 
                                                    : ($vuetify.breakpoint.md
                                                        ? 2/1
                                                        : ($vuetify.breakpoint.lg
                                                            ? 1 
                                                            : 1
                                                        )
                                                    )
                                                )"
                            class="align-center"
                        >
                            <v-card-text class="text-center white--text">
                                <p>
                                    Gerne gebe ich mein Wissen auch in Form von Workshops weiter.
                                    Wenn Du dich selbst oder Deine Mitarbeiter in Belangen der digitalen Technologien fortbilden willst, kannst Du Kurse für Einzelpersonen oder Gruppen buchen.
                                    Möglich sind zum Beispiel Einführungen in Wordpress/Woocommerce, HTML/CSS oder Vue.js/Vuetify.
                                    Du kannst auch einen individuellen Workshop mit mir planen.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <a id="philosophie"></a>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" lg="4">
                    <v-card class="patterned" color="cyan darken-1" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Philosophie</h2>
                                <h3 :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-1'">Wie arbeitet die WEBMACHERIN?</h3>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                    <v-card color="cyan darken-1" elevation="0" tile>
                        <v-img
                        alt="Ein Bildschirm mit Codezeilen - Fotograf: Florian Olivo"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1"
                        :src="require('@/assets/images/mood/florian-olivo-4hbJ-eymZ1o-unsplash.jpg')"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card color="cyan darken-1" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.mdAndDown ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p class="subtitle-2 text-uppercase mb-0">
                                    Kurz zusammengefasst:
                                </p>
                                <ul>
                                    <li>Ausführliche Beratung & transparente Kommunikation</li>
                                    <li>Open Source/Free Software mit großer Community</li>
                                    <li>Sicheres & grünes Hosting mit Entwicklerwerkzeugen</li>
                                    <li>Datensicherung auf DSGVO-konformen Cloud-Speicher</li>
                                    <li>Modernes Projektmanagement</li>
                                </ul>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-card color="cyan darken-1" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.mdAndDown ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p>
                                    Ich lege großen Wert auf eine ausführliche Beratung und transparente Kommunikation, so daß Du die passende Website für Deinen Anwendungszweck bekommst und immer gut betreut bist.
                                </p>
                                <p>
                                    Ich kommuniziere offen Vor- und Nachteile bestimmter Technologien und gebe Empfehlungen nur für Produkte, von denen ich selbst überzeugt bin.
                                        Über den Fortschritt Deines Projektes halte ich Dich selbstverständlich immer auf dem Laufenden.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-card color="cyan darken-1" elevation="0" tile>
                        <v-responsive :aspect-ratio="$vuetify.breakpoint.mdAndDown ? 2/1 : 1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p>
                                    Weiterhin möchte ich Dir die besten technischen Voraussetzungen bieten, um Dein Projekt sicher zu verwalten und weiter zu entwickeln.
                                        Das heißt Du bekommst ein Hosting, das guten Service bietet, gleichzeitig aber auch für Entwickler Werkzeuge bietet, um die Arbeit zu erleichtern. Und grüner Strom wird auch verwendet!
                                </p>
                                <p>
                                    Außerdem werden Deine Daten sicher und DSGVO-konform bei Anbietern in der EU gespeichert.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template> 

<style lang="scss">

</style>

<script>
import FlipCard from '../plugins/FlipCard.vue';

export default {
    name: "Home",
    components: {
        FlipCard,
    },
};
</script>
